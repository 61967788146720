// src/App.js
import React, { useState } from 'react';
import Gallery from './components/Gallery';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const App = () => {
  const [tag, setTag] = useState('home');

  const handleTagChange = (newTag) => {
    setTag(newTag);
  };

  return (
    <div className="App">
      <header className="navbar navbar-dark bg-dark text-white p-3 mb-4">
        <div className="container d-flex justify-content-between align-items-center">
          <span className="navbar-brand">Kwesiblack</span>
          <nav className="nav">
            <button
              className={`nav-link btn btn-link ${tag === 'home' ? 'active' : ''}`}
              onClick={() => handleTagChange('home')}
            >
              Home
            </button>
            <button
              className={`nav-link btn btn-link ${tag === 'pt' ? 'active' : ''}`}
              onClick={() => handleTagChange('pt')}
            >
              People
            </button>
            <button
              className={`nav-link btn btn-link ${tag === 'pl' ? 'active' : ''}`}
              onClick={() => handleTagChange('pl')}
            >
              Places
            </button>
            <button
              className={`nav-link btn btn-link ${tag === 'na' ? 'active' : ''}`}
              onClick={() => handleTagChange('na')}
            >
              Nature Walks
            </button>
            <button
              className={`nav-link btn btn-link ${tag === 'cl' ? 'active' : ''}`}
              onClick={() => handleTagChange('cl')}
            >
              Color
            </button>
          </nav>
        </div>
      </header>
      <div className="container">
        <Gallery tag={tag} />
      </div>
      <footer className="footer text-white text-center py-3">
        <div className="container">
          <span>© Kwesiblack.com 2024</span>
        </div>
      </footer>
    </div>
  );
};

export default App;