// src/components/Gallery.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Image, CloudinaryContext } from 'cloudinary-react';
import ReactPaginate from 'react-paginate';
import Modal from 'react-modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Gallery.css';

const Gallery = ({ tag }) => {
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const imagesPerPage = 20;

  useEffect(() => {
    const fetchImages = async () => {
      const url = `https://res.cloudinary.com/kwesiblack/image/list/${tag}.json`;
      try {
        const response = await axios.get(url);
        setImages(response.data.resources);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, [tag]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const offset = currentPage * imagesPerPage;
  const currentImages = images.slice(offset, offset + imagesPerPage);
  const pageCount = Math.ceil(images.length / imagesPerPage);

  return (
    <CloudinaryContext cloudName="kwesiblack">
      <div className="container">
        <div className="gallery">
          {currentImages.map((image) => (
            <div
              key={image.public_id}
              className="image-container"
              onClick={() => openModal(image)}
            >
              <Image publicId={image.public_id} className="img-fluid" width="300" height="200" crop="fill" />
            </div>
          ))}
        </div>
        <ReactPaginate
          previousLabel={'previous'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination justify-content-center'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          previousLinkClassName={'page-link'}
          nextClassName={'page-item'}
          nextLinkClassName={'page-link'}
          //breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          activeClassName={'active'}
        />
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Image Modal"
          className="modal"
          overlayClassName="overlay"
        >
          {selectedImage && (
            <div className="modal-content">
              <button className="btn-close-modal" onClick={closeModal}>
                &times;
              </button>
              <Image publicId={selectedImage.public_id} className="img-fluid" />
            </div>
          )}
        </Modal>
      </div>
    </CloudinaryContext>
  );
};

export default Gallery;